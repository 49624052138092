import PropTypes from 'prop-types';
import React, {useState, useContext, useEffect} from "react";
//3rd party
import cx from "classnames";
import dayjs from "dayjs";
// project
import {AppState} from "../../Context/AppContext";
import {ModalHelper} from "../../Utility/Modal";
import Api from "../../Api/Api";
import EventDispatcher from "../../Utility/EventDispatcher";
import FormElementText from "../../Form/Element/Text";
import FormElementSelect from "../../Form/Element/Select";
import Tabs from '../../Utility/Tabs/Tabs'
import Tab from "../../Utility/Tabs/Tab";
import waitingList from "./WaitingList";

const WaitingParty = (props) => {
    // global state
    const [RhostState] = useContext(AppState);
    const [state, dispatch] = useContext(AppState);
    // local state
    const [tab, setTab] = useState('waiting')
    const [waiting, setWaiting] = useState(() => {
        if (props.waiting) {
            return props.waiting;
        }

        let nextSlot = new Date();
        nextSlot.setMilliseconds(Math.ceil(nextSlot.getMilliseconds() / 1000) * 1000);
        nextSlot.setSeconds(Math.ceil(nextSlot.getSeconds() / 60) * 60);
        nextSlot.setMinutes(Math.ceil(nextSlot.getMinutes() / 15) * 15);
        return {
            covers: 4,
            time_promised_minute: (nextSlot.getMinutes() < 10 ? '0' : '') + nextSlot.getMinutes(),
            time_promised_hour: nextSlot.getHours()
        }
    })
    const [showMessages, setShowMessages] = useState(false);
    const [smsSending, setSmsSending] = useState(false);
    const [sms, setSms] = useState({ error: false, sending: false, message: '' })
    const [errors, setErrors] = useState([])
    const [serverError, setServerError] = useState(null)
    const [recommendedTables, setRecommendedTables] = useState([]);
    const [creatingReservation, setCreatingReservation] = useState(false);

    const handleSendSMS = (message) => {
        setSmsSending(true);
        setSms({ error: false, sending: true, message: message.id, });
        setShowMessages(false);
        Api.post('rest/res/wait-list/message', {
            message_id: message.id,
            bar_id: state.bar.id,
            wait_list_id: waiting.id
        }, () => {
            setSms({ error: false, sending: false, message: ''});
            setWaiting(prev => {
                const state = {...prev, is_sms_sent: true}
                dispatch({
                    type: 'UPDATE_WAITING',
                    payload: state
                })
                return state
            })
            ModalHelper.closeModal()
            setSmsSending(false)
            EventDispatcher.dispatch('show-notification', 'Message has been sent');
        }, (xhr, status, err, data, errorMessage) => {
            setSms({ error: true,  sending: false, message: '', })
            setSmsSending(false)
            setServerError(errorMessage)
            EventDispatcher.dispatch('show-error', errorMessage);
        });
    }

    const handleSeatReservation = () => {
        let errorList = [];
        if (waiting.tables === undefined || waiting.tables === null || waiting.tables.length === 0) {
            errorList['tables'] = 'Select at least one table';
        }

        setErrors(errorList)

        if (Object.keys(errorList).length === 0) {
            setCreatingReservation(true);
            Api.post('rest/res/wait-list/seat', {
                bar_id: state.bar.id,
                wait_list_id: waiting.id,
                tables: waiting.tables
            }, (data) => {
                EventDispatcher.dispatch('show-notification', 'Successfully seated wait list customer');
                EventDispatcher.dispatch('reload-waiting-list')
                setCreatingReservation(false);
                ModalHelper.closeModal()
            })
        }
    }

    const handleCancelWaiting = () => {
        Api.delete('rest/res/wait-list', {bar_id: state.bar.id, wait_list_id: waiting.id}, () => {
            setWaiting(prev => {
                const state = {...prev, is_deleted: true}
                dispatch({
                    type: 'UPDATE_WAITING',
                    payload: state
                })
                EventDispatcher.dispatch('reload-waiting-list')
                return state
            })
            ModalHelper.closeModal()
            EventDispatcher.dispatch('show-notification', 'Wait list has been cancelled');
        }, (xhr, status, err, data, errorMessage) => {
            EventDispatcher.dispatch('show-error', errorMessage);
        });
    }

    const handleFieldChange = (e) => {
        setErrors([])
        const value = e.target.value;
        const name = e.target.name;

        switch (name) {
            case 'name':
                setWaiting(prev => ({...prev, name: value}))
                break;
            case 'mobile':
                setWaiting(prev => ({...prev, mobile: value}))
                break;
            case 'tables':
                setWaiting(prev => ({...prev, tables: value}))
                break;
            case 'comments':
                setWaiting(prev => ({...prev, comments: value}))
                break;
            case 'time_promised_hour':
                setWaiting(prev => ({...prev, time_promised_hour: value}))
                break;
            case 'time_promised_minute':
                setWaiting(prev => ({...prev, time_promised_minute: value}))
                break;
            // no default
        }
    }

    const handleAddTable = (tableId) => {
        let tables = [];

        if (waiting.tables !== undefined) {
            tables = JSON.parse(JSON.stringify(waiting.tables));
        }

        tables.push(tableId);

        setWaiting(prev => ({...prev, tables: tables}))
    }

    const validateWaitingList = () => {
        let errorList = [];
        if (waiting.name === undefined || waiting.name === null || waiting.name.trim() === '') {
            errorList['name'] = 'Enter a name';
        }
        if (waiting.mobile === undefined || waiting.mobile === null || waiting.mobile.trim() === '') {
            errorList['mobile'] = 'Enter a valid mobile number';
        }
        if (waiting.covers === undefined || waiting.covers === null || waiting.mobile.trim() === '') {
            errorList['covers'] = 'Enter the number of covers';
        }
        setErrors(errorList);
        return errorList;
    }

    const isTableValid = (table) => {
        const foundTable = state.tables.find((stateTable) => {
            return stateTable.name === table.name;
        })

        return !foundTable.active_booking;
    }

    useEffect(() => {

        if (waiting.id) {
            Api.get('rest/res/wait-list/recommended-tables', {
                bar_id: state.bar.id,
                wait_list_id: waiting.id
            }, function (data) {
                let filteredTables = Object.fromEntries(
                    Object.entries(data).filter(([key, value]) => isTableValid(value))
                )

                setRecommendedTables(filteredTables)
            })
        }

        const handleSave = () => {
            let formErrors = validateWaitingList()

            if (Object.keys(formErrors).length === 0) {
                let parameters = {
                    bar_id: state.bar.id,
                    name: waiting.name,
                    covers: waiting.covers,
                    comments: waiting.comments,
                    mobile: waiting.mobile,
                    time_promised_hour: waiting.time_promised_hour,
                    time_promised_minute: waiting.time_promised_minute,
                };
                if (waiting.id) {
                    parameters['id'] = waiting.id;
                    Api.post('rest/res/wait-list/edit', parameters, (data) => {
                        setWaiting(data.data)
                        EventDispatcher.dispatch('show-notification', 'Wait list has been updated');
                        dispatch({
                            type: 'UPDATE_WAITING',
                            payload: data.data
                        })
                        ModalHelper.closeModal()
                    }, (xhr, status, err, data, errorMessage) => {
                        setServerError(errorMessage)
                    })
                } else {
                    Api.post('rest/res/wait-list/add', parameters, (data) => {
                        setWaiting(data.data)
                        EventDispatcher.dispatch('show-notification', 'Wait list has been updated');
                        dispatch({
                            type: 'UPDATE_WAITING',
                            payload: data.data
                        })
                        ModalHelper.closeModal()
                    }, (xhr, status, err, data, errorMessage) => {
                        setServerError(errorMessage)
                    })
                }
            }
        }

        ModalHelper.addSaveButton(handleSave)
    }, [dispatch, errors])

    let hours = ['07', '08', '09', 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, '00', '01', '02', '03', '04'];
    let minutes = ['00', 15, 30, 45];
    let hourList = [], minuteList = [];
    hours.forEach(hour => {
        hourList.push({
            key: hour,
            value: hour
        });
    })
    minutes.forEach(minute => {
        minuteList.push({
            key: minute,
            value: minute
        });
    })

    return (
        <div className='reservation'>
            <Tabs initial_tab={tab} handleChange={(tab) => {
                setTab(tab);
            }}>
                <Tab id="waiting" title="Reservation Details">
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap'
                        }}
                        className='res-info-wrapper'
                    >
                        {waiting.is_sms_sent && <div className="col-md-12"><div className="alert alert-warning">
                            <span className="fa fa-envelope" style={{marginRight: '10px'}} /> An SMS has been sent to this wait list customer. See history for details.
                        </div></div> }
                        {serverError && <div className="col-md-12"><div className="alert alert-danger">{serverError}</div></div>}
                        <div className='col-md-12' style={{marginBottom: '15px'}}>
                            <div className={cx('input-group covers', {'has-error': errors.covers})}>
                                <div
                                    style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'flex-end'}}
                                    className="covers-group"
                                >
                                    <div className="btn-group">
                                        <label className="control-label" style={{width: '100%'}} htmlFor="cover-numbers">Covers</label>
                                        {[1, 2, 3, 4, 5, 6, 7, 8].map((c) => (
                                            <button
                                                style={{border: '2px solid #eeeeee'}}
                                                key={c}
                                                data-covers="c"
                                                onClick={() => {
                                                    setWaiting(prev => ({...prev, covers: c}));
                                                    setErrors([])
                                                }}
                                                className={cx('btn btn-lg', {active: waiting.covers === c})}
                                                name="cover-numbers"
                                            >
                                                {c}
                                            </button>
                                        ))}
                                    </div>

                                    {waiting.covers >= 8 &&
                                        <div style={{width: '20%', margin: '0 auto 0 0'}} >
                                            <label htmlFor="covers">Custom Covers</label>
                                            <FormElementText
                                                number={true}
                                                name="covers"
                                                value={waiting.covers}
                                                onChange={(e) => setWaiting(prev => ({...prev, covers: +e.target.value}))}
                                            />
                                        </div>
                                    }
                                </div>
                                {errors.covers && <span className="help-block">{errors.covers}</span>}
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <FormElementText
                                name="name"
                                onChange={handleFieldChange}
                                value={waiting.name}
                                prepend="Name"
                                is_large={true}
                                error={errors.name}
                                help={errors.name}
                            />
                        </div>
                        <div className='col-md-12'>
                            <FormElementText
                                name="mobile"
                                onChange={handleFieldChange}
                                value={waiting.mobile}
                                prepend="Mobile"
                                is_large={true}
                                error={errors.mobile}
                                help={errors.mobile}
                            />
                        </div>
                        <div className="col-md-6">
                            <FormElementSelect
                                onChange={handleFieldChange}
                                values={hourList}
                                prepend="Time Promised"
                                name="time_promised_hour"
                                is_large={true}
                                selectedValue={waiting.time_promised_hour}
                            />
                        </div>
                        <div className="col-md-6">
                            <FormElementSelect
                                onChange={handleFieldChange}
                                values={minuteList}
                                prepend="&nbsp;"
                                is_large={true}
                                name="time_promised_minute"
                                selectedValue={waiting.time_promised_minute}
                            />
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                            <label htmlFor='comments'>Comments</label>
                                <textarea
                                    id='comments'
                                    style={{minWidth: '405px', width: '100%', minHeight: '60px'}}
                                    name='comments'
                                    value={waiting.comments}
                                    className="form-control input-lg"
                                    onChange={handleFieldChange}
                                />
                            </div>
                        </div>

                        {waiting.id &&
                            <div className="col-md-12">
                                <FormElementSelect
                                    name="tables"
                                    prepend="Tables"
                                    selectedValue={waiting.tables}
                                    values={RhostState.tables.map((table) => ({ key: table.id, value: table.name }))}
                                    useSelect2={true}
                                    onChange={handleFieldChange}
                                    multiple={true}
                                    error={errors.tables}
                                    help={errors.tables}
                                />
                                <p style={{wordWrap: 'break-word'}}><small>Suggested Tables</small>:<br />{recommendedTables.length === 0 ? 'None yet' : Object.keys(recommendedTables).map(function (key, index) {
                                    return  waiting.tables && waiting.tables.includes(recommendedTables[key]['id'])
                                        ? ''
                                        : <span
                                            onClick={() => handleAddTable(recommendedTables[key]['id'])}
                                            key={index}
                                            className={cx('btn btn-xs', {'btn-success': recommendedTables[key]['seats'] === waiting.covers, 'btn-default': recommendedTables[key]['seats'] !== waiting.covers})}
                                            style={{margin: '3px 5px 5px 0', display: 'inline-block'}}
                                        >
                                        {recommendedTables[key]['name']}
                                    </span>
                                })}</p>
                            </div>
                        }
                        {waiting.id && <>
                            <div className="col-md-4" style={{margin: '5px 0 15px 0'}}>
                                    <button className="btn btn-danger btn-large btn-lg" style={{width: '100%'}}
                                            onClick={handleCancelWaiting}
                                    >
                                        <span className="fa fa-trash-alt" style={{marginRight: '15px'}} />
                                        Delete Wait List
                                    </button>
                                </div>
                            <div className="col-md-4" style={{margin: '5px 0 15px 0'}}>
                                    <div className="reservation-actions" style={{margin: 0, display: 'block'}}>
                                    <div className="reservation-message-send">
                                        <div className={showMessages ? 'open' : ''}>
                                            <button
                                                type="button"
                                                onClick={() => setShowMessages(!showMessages)}
                                                className={cx('btn btn-lg dropdown-toggle btn-info', {'btn-danger': sms.error, 'disabled': waiting.is_sms_sent})}
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="true"
                                                disabled={sms.sending}
                                                style={{width: '100%'}}
                                            >
                                                <span className="fa fa-mobile-alt" style={{marginRight: '15px'}} />
                                                {sms.sending && 'Sending....'}
                                                {!sms.sending && !waiting.is_sms_sent && 'Send Message'}
                                                {waiting.is_sms_sent ? 'Send Another Message' : <span className="caret"/>}
                                            </button>
                                            <ul className="dropdown-menu">
                                                {state.message_templates?.length === 0 && <li>No Messages Available</li>}
                                                {state.message_templates?.map((message) => (
                                                    <li key={message.id}>
                                                        <button
                                                            style={{width: '100%'}}
                                                            className="btn-lg"
                                                            disabled={smsSending}
                                                            aria-disabled={smsSending}
                                                            onClick={()=> handleSendSMS(message)}>
                                                            <span className="fa fa-mobile-alt" style={{marginRight: '15px'}} />
                                                            {message.content}
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-md-4" style={{margin: '5px 0 15px 0'}}>
                                    <button className="btn btn-primary btn-large btn-lg" style={{width: '100%'}}
                                            disabled={creatingReservation}
                                            onClick={handleSeatReservation}
                                    >
                                        {creatingReservation
                                            ? <><span className="fa fa-spin fa-circle-notch" style={{marginRight: '15px'}} /> Creating Reservation</>
                                            : <><span className="fa fa-utensils" style={{marginRight: '15px'}} /> Seat Customer</>
                                        }
                                    </button>
                                </div>
                        </>
                        }
                    </div>
                </Tab>
                {waiting.id &&
                <Tab id="history" title="History">
                    <div className="table-wrap">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Entry</th>
                            </tr>
                            </thead>
                            <tbody>
                            {waiting.log.data.map((history) => {
                                return <tr key={history.id}>
                                    <td>{dayjs(history.date_time_added).format('DD/MM/YYYY HH:mm:ss')}</td>
                                    <td>{history.details}</td>
                                </tr>
                            })}
                            </tbody>
                        </table>
                    </div>
                </Tab>}
            </Tabs>
        </div>
    )
}

export default WaitingParty

WaitingParty.propsType = {
    waiting: PropTypes.object,
}