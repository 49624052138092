import React, {useCallback, useContext, useEffect, useState} from "react";
// project
import {AppState} from "../../Context/AppContext";
import {ModalHelper} from "../../Utility/Modal";
import EventDispatcher from "../../Utility/EventDispatcher";
import WaitingParty from "./WaitingParty";
import Api from "../../Api/Api";

const WaitingList = () => {
    // global state
    const [state, dispatch] = useContext(AppState);

    const handleOpenWaiting = (waiting) => {
        EventDispatcher.dispatch('open-waiting', waiting)
    }
    const reloadWaitingList = () => {
        Api.get('rest/res/wait-list', {bar_id: state.bar.id}, function (data) {
            dispatch({
                type: 'SET_WAITING_LIST',
                payload: data.data
            })
        })
    }
    useEffect(() => {
        reloadWaitingList()
        const openWaiting = EventDispatcher.subscribe('open-waiting', (waiting) => {
            ModalHelper.openModal(waiting.name, <WaitingParty waiting={waiting}/>);
        })
        const reloadListener = EventDispatcher.subscribe('reload-waiting-list', () => {
            reloadWaitingList()
        })
        return () => {
            EventDispatcher.unsubscribe(reloadListener);
            EventDispatcher.unsubscribe(openWaiting);
        }
    }, [])
    return (
        <ul className='waiting-list'>
            {state.waitingList.map((item) => (
                <button
                    key={item.id}
                    className='btn btn-default btn-sm res-info'
                    onClick={() => handleOpenWaiting(item)}
                >
                    <li>
                        <span className='covers'>
                            {item.covers}
                            <i className='far fa-user'/>
                        </span>
                        <span className='name'>
                            {item.name}
                        </span>
                        {item.is_sms_sent &&
                            <span style={{marginRight: '10px'}}>
                                <i className='far fa-envelope text-primary'/>
                            </span>
                        }
                        <span className="promised-time">
                            {item.time_promised_hour}:{(item.time_promised_minute.length === 1 && item.time_promised_minute.length < 10 ? '0' : '') + item.time_promised_minute}
                        </span>
                    </li>
                </button>
            ))}
        </ul>
    )
}

export default WaitingList

WaitingList.propTypes = {

}