import * as dayjs from "dayjs";
import cx from 'classnames';
import Settings from "../Settings";
import _ from 'underscore';

export const getBusinessDay = (date) => {
    let dayModel = dayjs(date);
    if (dayModel.format('H') < 7) {
        dayModel = dayModel.add(-1, 'day');
    }
    return dayjs(dayModel.format('YYYY-MM-DD') + ' 07:00:00');
}
const start = getBusinessDay(dayjs());
const end = start.add(1, 'day').add(-1, 'second');

const timeDiffString = (time1, time2) => {
    let diff = dayjs(time1).diff(dayjs(time2), 'm');
    let hours = Math.floor(diff / 60);
    let minutes = diff - (hours * 60);
    let parts = [];
    if (hours > 0) {
        parts.push(hours + 'H');
    }
    if (minutes > 0) {
        parts.push(minutes + 'M');
    }
    return parts.join(' ');
}

export {timeDiffString};

const timeDiffStringUnix = (time1, time2) => {
    let seconds = time1 - time2;
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds - (hours * 3600)) / 60);
    let parts = [];
    if (hours > 0) {
        parts.push(hours + 'H');
    }
    if (minutes > 0) {
        parts.push(minutes + 'M');
    }
    return parts.join(' ');
}
export {timeDiffStringUnix};
export const defaultState = {
    time_travel: false,
    current_time: null,
    selected_time: null,
    selected_time_ts: null,
    current_time_ts: null,
    start: start,
    end: end,
    unread_chat: 0,
    last_tick: null,
    business_date: getBusinessDay(dayjs()),
    move_reservation: null,
    settings: {
        endpoint: '/api/',
        device_id: null,
        token: null,
        status_no_show: 90,
        status_upcoming: 10,
        status_seated: 20,
        status_finalised: 60,
        status_finished: 70,
        time_slot_length: 15,
        tick_interval: 10000,
        business_date_start: '07:00',
        update_reservations_after: 120
    },
    screen: {
        width: 0,
        visible_height: 0,
        ratio: 0,
        base_width: 827,
        base_height: 640
    },
    day_slots: [],
    device_not_found: null,
    offline: true,
    device: {},
    bar: {},
    layouts: [],
    areas: [],
    sessions: [],
    levels: [],
    active_session: null,
    active_layout: null,
    reservations: [],
    bookings: [],
    tables: [],
    statuses: [],
    session_mapping: [],
    pusher_key: null,
    last_reservation_update: null,
    reservation_date: null,
    warnings: [],
    walkins: [],
    waitingList: [],
    message_templates: [],
    ignored_warnings: []
}
export const ResState = {
    state: defaultState,
    setState: function (state) {
        this.state = state;
    },
    getTableById: function (id) {
        return this.state.tables.find((t) => {
            return t.id === id;
        });
    },
    getAreaById: function (id) {
        return this.state.areas.find((t) => {
            return t.id === id;
        });
    },
    createSlotArray: function (start, end) {
        start = dayjs(start);
        end = dayjs(end);
        let slots = [];
        while (start <= end) {
            slots.push(start.format('HH:mm'));
            start = start.add(Settings.time_slot_length, 'minute');
        }
        return slots;
    },
    createFullDateArray: function (start, end) {
        start = dayjs(start);
        end = dayjs(end);
        let slots = [];
        while (start <= end) {
            slots.push(start.format('YYYY-MM-DD HH:mm'));
            start = start.add(Settings.time_slot_length, 'minute');
        }
        return slots;
    },
    getTimeSlots: function () {
        if (this.state.selected_time) {
            let start = getBusinessDay(this.state.selected_time);
            let end = start.add(1, 'day').add(-1, 'second');
            return this.createFullDateArray(start, end);
        }
        return [];
    },
    getFutureTimeSlots: function () {
        let now = dayjs().add(-30, 'minute');
        return this.getTimeSlots().filter((s) => {
            return dayjs(s).isAfter(now);
        })
    },
    getWarningsForReservation: function (data, res_state) {
        if (!res_state) {
            res_state = this.state;
        }
        let state = JSON.parse(JSON.stringify(res_state));
        let reservation = JSON.parse(JSON.stringify(data));
        state.active_layout = this.getLayoutForTime(reservation.start_time_ts);
        let table_ids = reservation.table_ids;
        reservation.slots = this.createSlotArray(reservation.start_time, reservation.end_time);
        if (state.active_layout) {
            state.active_layout.detail.data.forEach((d) => {
                if (reservation.level_ids.length > 0) {
                    if (reservation.level_ids.indexOf(d.level_id) > -1) {
                        table_ids.push(d.table_id);
                    }
                }
                if (reservation.level_ids.length > 0) {
                    if (reservation.area_ids.indexOf(d.area_id) > -1) {
                        table_ids.push(d.table_id);
                    }
                }
            });
        }
        reservation.table_ids = _.unique(table_ids);
        let found = false;
        state.reservations = state.reservations.map((r) => {
            if (r.id === reservation.id) {
                found = true;
                return reservation;
            }
            return r;
        });
        if (found === false) {
            state.reservations.push(reservation);
        }
        state = this.postStateChecks(state);
        return state.warnings.filter((w) => {
            if (w.type === 'table' && reservation.table_ids.indexOf(w.table) > -1) {
                return true;
            }
            return false;
        });
    },
    updateReservations: function (state) {
        let res = JSON.parse(JSON.stringify(state.reservations));
        let nowUnix = dayjs().unix();
        return res.map((r) => {
            r.seconds_late = 0;
            if (r.start_time_ts) {
                r.seconds_late = nowUnix - r.start_time_ts;
            }
            return r;
        });
    },
    postStateChecks: function (state) {
        state.reservations = this.updateReservations(state);
        state.tables = this.updateTableSlots(state);
        state.warnings = this.getWarnings(state.tables, state.reservations);
        if (state.selected_time) {
            state.selected_time_ts = dayjs(state.selected_time).unix();
        }
        if (state.current_time) {
            state.current_time_ts = dayjs(state.current_time).unix();
        }
        state.walkins = [];
        [2, 4, 6, 8].map((covers) => {
            let walkin = this.getNextWalkin(covers);
            if (walkin) {
                state.walkins.push({
                    covers: covers,
                    slot: walkin
                });
            }
            return covers;
        });
        return state;
    },
    getLayoutForTime: function (time) {
        let session = this.state.session_mapping.find((s) => {
            return s.start_ts <= time && s.end_ts >= time;
        });
        if (session) {
            return this.getLayoutById(session.layout_id);
        }
        return null;
    },
    getLayoutById: function (id) {
        if (!this.state.layouts.length) {
            return null;
        }
        return this.state.layouts.find((l) => {
            return l.id === id;
        })
    },
    getTableCapabilitiesForTime: function (table_id, time) {
        let layout = this.getLayoutForTime(time);
        if (layout) {
            let table = layout.detail.data.find((d) => {
                return d.table_id === table_id;
            });
            if (table) {
                return table.capabilities;
            }
        }
        return [];
    },
    updateTableSlots: function (state) {
        const timeSlots = state.day_slots.map((s) => {
            let ts = dayjs(s);
            let unix = ts.unix();
            return {
                time: s,
                time_ts: unix,
                time_time: ts.format('HH:mm'),
                id: ts.format('HHmm'),
                res_ids: [],
                capabilities: []
            }
        });
        return state.tables.map((t) => {
            let reservations = this.getReservationsForTable(t.id, state);
            t.reservations = reservations;
            t.active_booking = false;
            t.current_late_reservation = null;
            t.next_reservation = null;
            t.can_take_walk_in = true;
            let classNames = ['tb-table', 'table-' + t.type.data.id, t.type.data.class, 'table-id-' + t.id, 'table-size-' + t.seats];
            t.status = {
                message: 'Status',
                background_colour: 'FFF',
                text_colour: '000',
                show_progress: false,
                time_diff: ''
            };
            t.slots = [];
            timeSlots.map((s) => {
                t.slots.push(JSON.parse(JSON.stringify(s)));
                return s;
            });
            t.warnings = [];
            t.warning_slots = [];
            if (t.dummy) {
                classNames.push('dummy');
            }
            let hasWalkIn = false;
            t.slots = t.slots.map(function (s) {
                s.reservations = reservations.filter((r) => {
                    return r.slots.indexOf(s.time_time) > -1;
                });
                s.res_ids = reservations.filter((r) => {
                    return r.slots.indexOf(s.time_time) > -1;
                }).map((r) => {
                    return r.id;
                });
                if (s.res_ids.length > 1) {
                    t.warning_slots.push(s.time_time);
                }
                s.capabilities = this.getTableCapabilitiesForTime(t.id, s.time_ts);
                if (s.capabilities.indexOf(2) > -1) {
                    hasWalkIn = true;
                }
                return s;
            }.bind(this));
            if (hasWalkIn === false) {
                classNames.push('no-walk-in');
            }
            if (t.warning_slots.length > 0) {
                t.warnings.push('Table ' + t.name + ' has conflicts at ' + t.warning_slots.join(', '));
            }
            let now = dayjs();
            let nowUnix = now.unix();
            if (state.time_travel === true) {
                let time = state.selected_time_ts;
                let active = reservations.filter((r) => {
                    return r.start_time_ts <= time && r.end_time_ts >= time;
                });
                if (active.length > 0) {
                    let seconds = active[0].end_time_ts - time;
                    let hours = Math.floor(seconds / 3600);
                    let minutes = (seconds - (hours * 3600)) / 60;
                    if (hours > 0) {
                        t.status.message = hours + 'H ' + minutes + 'M';
                    } else {
                        t.status.message = minutes + 'M';
                    }
                    classNames.push('active-booking');
                } else {
                    let next_slot = t.slots.filter((s) => {
                        return s.time_ts >= state.selected_time_ts && s.res_ids.length > 0;
                    });
                    if (next_slot.length === 0) {
                        t.status.message = 'FREE';
                    } else {
                        t.status.message = 'FREE';
                        t.status.time_diff = timeDiffStringUnix(next_slot[0].time_ts, state.selected_time_ts);
                    }
                }
            } else {
                let active = reservations.filter((r) => {
                    return r.active;
                })
                t.current_reservation = null;
                if (active.length > 0) {
                    let status = {
                        completed: active[0].status_completed,
                        colour: active[0].status_colour,
                        text_colour: active[0].status_text_colour,
                        name: active[0].status_name,
                        time_diff: '',
                    };
                    classNames.push('active-booking');
                    t.active_booking = true;
                    t.status.message = status.name;
                    t.current_reservation = active[0];
                    if (active[0].end_time_ts < nowUnix) {
                        t.status.background_colour = 'FF0000';
                        t.status.show_progress = true;
                        t.status.progress = 100;
                    } else {
                        t.status.background_colour = status.colour;
                        t.status.text_colour = status.text_colour;
                        t.status.show_progress = true;
                        let start = active[0].start_time_ts;
                        let end = active[0].end_time_ts;
                        let total = end - start;
                        t.status.progress = Math.ceil((100 / total) * (nowUnix - active[0].start_time_ts));
                    }
                } else {
                    let late = reservations.filter((r) => {
                        return r.status_completed === false && r.start_time_ts <= nowUnix;
                    }).sort((r1, r2) => {
                        return r1.start_time_ts > r2.start_time_ts ? 1 : -1;
                    });
                    let upcoming = reservations.filter((r) => {
                        return r.status_completed === false && r.start_time_ts > nowUnix;
                    });
                    if (late.length > 0) {
                        t.current_late_reservation = late[0];
                        t.current_late_reservation.time_late = nowUnix - late[0].start_time_ts;
                        t.status.background_colour = 'FF0000';
                        t.status.message = 'LATE';
                        t.status.time_diff = timeDiffStringUnix(nowUnix, late[0].start_time_ts)
                        t.status.text_colour = 'FFFFFF';
                    } else if (upcoming.length > 0) {
                        t.next_reservation = upcoming[0];
                        let diff_in_secs = upcoming[0].start_time_ts - nowUnix;
                        t.can_take_walk_in = diff_in_secs > (Settings.time_slot_length * 60 * 8);
                        t.status.message = 'FREE';
                        t.status.time_diff = timeDiffStringUnix(upcoming[0].start_time_ts, nowUnix)
                    } else {
                        t.status.message = 'FREE';
                    }
                }
            }
            t.className = cx(classNames);
            return t;
        })
    },
    getNextWalkin: function (covers) {
        let available = [];
        var min_time = null;
        if (this.state.time_travel) {
            return null;
        }
        this.state.tables.forEach((t) => {
            if (t.dummy) {
                return false;
            }
            if (t.seats < covers) {
                return false;
            }
            t.slots.forEach((s, index) => {
                let slotAvailable = this.slotIsAvailable(s, t.slots, 2);
                if (slotAvailable) {
                    available.push({slot: s, table: t});
                    if (!min_time || min_time > s.time_ts) {
                        min_time = s.time_ts;
                    }
                }
                return s;
            });
        });
        if (available.length > 0) {
            let availability = available.filter((a) => {
                return a.slot.time_ts === min_time;
            }).sort((a1, a2) => {
                return a1.table.seats < a2.table.seats;
            });
            return availability[0];
        }
        return null;
    },
    slotIsAvailable: function (slot, slots, capability, length) {
        if (!length && this.state.active_session) {
            length = this.state.active_session.default_reservation_length;
        }
        if (!length) {
            length = 120;
        }
        var target_slot_length = Math.ceil(length / Settings.time_slot_length);
        let start_index = slots.findIndex((s) => {
            return s.time === slot.time;
        });
        if (start_index < 0) {
            return false;
        }
        let slice = slots.slice(start_index, target_slot_length + start_index);
        if (slice.length < target_slot_length) {
            return false;
        }
        let valid = true;
        let now = dayjs();
        let nowUnix = now.unix();
        slice.forEach((s) => {
            if (s.time_ts < nowUnix) {
                valid = false;
            }
            if (s.capabilities.indexOf(capability) === -1) {
                valid = false;
            }
            if (s.res_ids.length > 0) {
                valid = false;
            }
        });
        return valid;
    },
    getWarnings: function (tables, reservations) {
        let warnings = [];
        let counts = {};
        let noxUnix = dayjs().unix();
        tables.map((t) => {
            counts[t.id] = t.seats;
            t.warnings.map((w) => {
                //Add conflict warnings
                warnings.push({type: 'table', table: t.id, 'warning': w, can_ignore: true, key: w});
                return w;
            })
            return t;
        });
        reservations.map((r) => {
            let table_total = 0;
            r.table_ids.map((t) => {
                if (counts[t]) {
                    table_total = table_total + counts[t];
                }
                return t;
            });
            let late_seconds = noxUnix - r.start_time_ts;
            if (r.status_id === Settings.status_upcoming && late_seconds > (60 * 10)) {
                let parts = [];
                if (late_seconds > 3600) {
                    let late_hours = Math.floor(late_seconds / 3600);
                    parts.push(late_hours + 'h');
                    late_seconds = late_seconds - (late_hours * 3600);
                }
                if (late_seconds > 60) {
                    let late_mins = Math.floor(late_seconds / 60);
                    parts.push(late_mins + 'm');
                    late_seconds = late_seconds - (late_mins * 60);
                }
                warnings.push({
                    type: 'reservation',
                    reservation: r.id,
                    warning: r.display_name + ' is ' + parts.join(' ') + ' late',
                    can_ignore: false,
                    key: 'late' + r.id
                });
            }
            if (r.covers > table_total) {
                warnings.push(
                    {
                        type: 'reservation',
                        reservation: r.id,
                        can_ignore: true,
                        key: 'covers' + r.id,
                        warning: r.display_name + ' at ' + dayjs(r.start_time).format('HH:mm') + ' is for ' + r.covers + ' covers, but assigned tables can seat max of ' + table_total
                    }
                );
            }
            return r;
        });
        warnings = warnings.map((w) => {
            w.key = this.state.start.format('YYYYMMDD') + w.key
            return w;
        })
        return warnings;
    },
    getReservationById: function (id) {
        return this.state.reservations.find((r) => {
            return r.id === id;
        })
    },
    getLevelById: function (id) {
        return this.state.levels.find((l) => {
            return l.id === id;
        })
    },
    getReservationsForTable: function (id, state) {
        if (!state) {
            state = this.state;
        }
        return state.reservations.filter((r) => {
            return r.table_ids.indexOf(id) > -1;
        })
    }
}