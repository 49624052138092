import React, {useContext, useEffect, useState} from 'react';
// 3rd party
import dayjs from "dayjs";
// project
import {AppState} from "../Context/AppContext";
import {ModalHelper} from "../Utility/Modal";
import EventDispatcher from "../Utility/EventDispatcher";
import Api from "../Api/Api";
import Collapse from "../Utility/Collapse";
import ReservationRow from "./ReservationRow";
import NewBooking from "../NewBooking/NewBooking";
import UnassignedBookingRow from "./UnassignedBookingRow";
import WaitingList from "./WaitingList/WaitingList";
import WaitingParty from './WaitingList/WaitingParty.js'
// assets
import './RightColumn.scss'

const RightColumn = () => {
    const [state] = useContext(AppState);
    const [show, setShow] = useState({
        food: true,
        party: true
    });
    const [upcoming, setUpcoming] = useState([]);
    const [unassignedBookings, setUnassignedBookings] = useState([]);

    const handleAddWaiting = () => {
        EventDispatcher.dispatch('add-new-waiting')
    }
    useEffect(() => {
        const addNewWaiting = EventDispatcher.subscribe('add-new-waiting', () => {
            ModalHelper.openModal('Waiting List', <WaitingParty />);
        })
        return () => EventDispatcher.unsubscribe(addNewWaiting);
    } , [])
    useEffect(() => {
        let res = state.reservations.filter((r) => {
            return r.status_id === 10;
        });
        if (show.party === false) {
            res = res.filter((r) => {
                return r.is_party_booking === false;
            });
        }
        if (show.food === false) {
            res = res.filter((r) => {
                return r.is_food_booking === false;
            });
        }
        setUpcoming(res.sort((res1, res2) => {
            if (res1.start_time_ts === res2.start_time_ts) {
                return res1.id > res2.id ? 1 : -1;
            }
            return res1.start_time_ts > res2.start_time_ts ? 1 : -1;
        }));
    }, [state.reservations, show]);
    useEffect(() => {
        setUnassignedBookings(state.bookings.filter((b) => {
            let res = state.reservations.find((r) => {
                return r.booking_id === b.id;
            });
            return !!res;
        }));
    }, [state.bookings, state.reservations]);
    return <div className="right-col" style={{height: (state.screen.base_height * state.screen.ratio) + 100}}>
        {!state.time_travel ?
            <Collapse header={"Next free tables " + dayjs().format('ddd Do MMM')}>
                <ul className="next-slots">
                    {state.walkins.map((walkin) => {
                        return <li className="available-slot" key={walkin.covers}>
                            <button className="btn btn-default res-info" onClick={(e) => {
                                e.preventDefault();
                                let diff = Math.abs(dayjs().unix() - walkin.slot.slot.time_ts);
                                let status = 20;
                                if (diff > (60 * 15)) {
                                    status = 10;
                                }
                                EventDispatcher.dispatch('add-walk-in', {
                                    start_time: walkin.slot.slot.time,
                                    tables: [walkin.slot.table.id],
                                    covers: walkin.covers,
                                    status_id: status,
                                });
                            }}>
                                <div className="free-tables-group">
                                    <span className="covers">{walkin.covers} <i className="far fa-user"/></span>
                                    <span className="time">
                                        {walkin.slot.slot.time_time}
                                    </span>
                                    <span className="time-diff">
                        <i className="far fa-clock"/> {dayjs(walkin.slot.slot.time).fromNow(true)}
                    </span>
                                    <span className="tables">T
                                        {walkin.slot.table.name}
                                    </span>
                                </div>
                            </button>
                        </li>
                    })}
                </ul>
            </Collapse> : <>
                <div className="bookings-summary">
                    {state.reservations.length === 0 ? <>
                        <p style={{textAlign: 'center'}}>You don't have any bookings at all
                            on {dayjs(state.start).format('dddd Do MMMM')} 🙁
                        </p>
                    </> : <>
                        <h5 className="bookings-date">{dayjs(state.start).format('dddd Do MMMM')}</h5>
                        {state.rhost_stats && <>
                        <p className="bookings-title">{`You have ${state.rhost_stats.totals.bookings} booking${state.rhost_stats.totals.bookings === 1 ? '' : 's'}`}</p>
                        <hr/>
                        <div className="booking-content">
                            {state.rhost_stats.types.map((t, index) => {
                                return <div key={index}>
                                    <p className="booking-type">{t.booking_type_name}</p>
                                    <p>{t.bookings} Booking{parseInt(t.bookings) === 1 ? '' : 's'}</p>
                                    <p>{t.covers} Cover{parseInt(t.covers) === 1 ? '' : 's'}</p>
                                    <p>{t.pbr > 0 ? <>&nbsp;({Api.currencyFormatter().format(t.pbr)} PBR)</> : null}</p>
                                </div>
                            })}
                        </div></>}
                    </>}
                </div>
                <div className="create-booking">
                    <button className="btn btn-success" onClick={() => {
                        ModalHelper.openModal('Add New Booking', <NewBooking/>);
                    }}>Create Booking
                    </button>
                </div>
            </>}
        <Collapse
            header={`Waiting List (${state.waitingList.length})`}
            action={
                <button
                    className='btn btn-success btn-sm waiting-list-action'
                    onClick={handleAddWaiting}>
                    Add New
                </button>
            }
            opened={false}>
            <WaitingList/>
        </Collapse>
        <Collapse header={(state.time_travel ? 'Future ' : 'Upcoming ') + 'Reservations (' + upcoming.length + ')'}>
            <div className="upcoming-res-options">
                <span className="show">Filter:</span>
                <button className={'btn btn-' + (show.food ? 'success' : 'default')}
                        onClick={() => {
                            setShow({...show, food: !show.food})
                        }}>Food
                </button>
                <button className={'btn btn-' + (show.party ? 'success' : 'default')}
                        onClick={() => {
                            setShow({...show, party: !show.party})
                        }}>Party
                </button>
            </div>
            {upcoming.length === 0 ?
                <div className="well" style={{textAlign: 'center'}}><strong>No upcoming bookings</strong>
                </div> : null}
            <ul className="next-slots upcoming-res">
                {upcoming.map(function (res) {
                    return <ReservationRow reservation={res} key={res.id}/>
                })}
            </ul>
        </Collapse>
        <Collapse header={'Unassigned Bookings (' + unassignedBookings.length + ')'}>
            <ul className="next-slots">
                {state.bookings.map((booking) => {
                    return <UnassignedBookingRow booking={booking} key={booking.booking_id}/>
                })}
            </ul>
        </Collapse>
    </div>
}
export default RightColumn;