import PropTypes from 'prop-types';
import React, {useState} from 'react';

const Collapse = (props) => {
    const {opened = true} = props
    const [open, setOpen] = useState(opened);
    const handleOpen = () => setOpen(!open)
    
    let headerBgColour = open ? {backgroundColor: '#337ab7'} : {backgroundColor: '#41a5f0'};

    return <div className="collapse-container">
        <div style={{
            ...headerBgColour,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }}
             className="collapse-header"
        >
           <span onClick={handleOpen}>{props.header}</span>
            {props.action && <div style={{marginLeft: 'auto'}}>{props.action}</div>}
            <a href="#" onClick={handleOpen}>
                <i className={open ? "fa fa-angle-down" : 'fa fa-angle-up'}/>
            </a>
        </div>
        <div className="collapse-content" style={{display: open ? 'block' : 'none'}}>
            {props.children}
        </div>
    </div>
}

export default Collapse;

Collapse.propTypes = {
    header: PropTypes.string,
    action: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
    opened: PropTypes.bool,
}