import PropTypes from 'prop-types';
import $ from 'jquery'
import cx from 'classnames';
import {useEffect, useState} from 'react';

export default function FormElementText(props) {
    let [value, setValue] = useState(props.value);
    let className = props.className;
    if (!className) {
        className = 'form-control';
    }
    let id = props.id;
    if (!id) {
        id = props.name;
    }
    let type = 'text';
    if (props.number === true) {
        type = 'number';
    }
    if (props.email === true) {
        type = 'email';
    }
    useEffect(() => {
        setValue(props.value);
    }, [props.value])
    let element = <input type={type} 
                        name={props.name} 
                        id={id} 
                        value={value} 
                        placeholder={props.placeholder}
                        autoComplete="off"
                        className={cx(className, {'is-invalid': props.error, 'input-lg': props.is_large})}
                        onChange={(e) => {
                            setValue(e.target.value);
                            if (props.onChange) {
                                props.onChange(e);
                            }
                        }}
                        disabled={props.disabled}
                        onBlur={props.onBlur}
                        onFocus={props.onFocus} maxLength={props.maxLength} max={props.max}
                        min={props.min} />;    

    // This makes possible activating the input on touch screen devices
    useEffect(()=> {
    $('input').click(function(){
        $(this).focus()
    })
    },[value])

    if (props.prepend || props.append) {
        return <div className={cx("form-group", {'has-error': props.error})}>
            <label className="control-label" htmlFor={props.name}>{props.prepend ? props.prepend : props.append}</label>
            {element}
            {props.help && <span className="help-block">{props.help}</span>}
        </div>
    }
    return element;
}

FormElementText.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    defferredUpdate: PropTypes.bool,
    number: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    maxLength: PropTypes.number,
    error: PropTypes.bool,
    isLarge: PropTypes.bool,
    help: PropTypes.string
};